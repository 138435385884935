@import 'src/assets/stylesheets/colors.scss';

.spaceBwTables {
  margin-bottom: 15px;
    :global {
        .ant-descriptions-view {
          border-radius: 0px;
        }
      }
}

.contentTitleContainer {
  background-color: $ant_info_table_background;
  font-size: 14px;
  padding: 12px;
  border-color: $ant_info_table_border;
  border-width: 0 0 1px 0;
  border-style: solid;
}

.collapseContent {
  padding: 12px;
}

.collapse {
  border-radius: 0px;
  border: 1px solid $ant_info_table_border;
  :global {
    .ant-collapse-item {
      border-radius: 0px;
      border-color: $ant_info_table_border;
    }
    .ant-collapse-header {
      border-bottom: 0px;
      display: flex;
      margin: 5px;
    }
    .ant-collapse-content {
      border-color: $ant_info_table_border;
    }
    border-bottom: 0px;
  }

  .header {
    font-weight: 600;
  }
  td {
    vertical-align: baseline;
  }
}

.table {
  :global {
    .ant-descriptions-view {
      border-radius: 0px;
    }
  }
}

.packageButton {
  padding: 0px;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleTag {
  font-weight: 400;
}

.contentColumn {
  width: 303px;
}