@import 'src/assets/stylesheets/colors.scss';

.topSection {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.fullNameSection {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.inputContainer {
  width: 100%;
}

.nameSection {
  width: 50%;
}

.phoneSection {
  width: 48%;
}
