@import 'src/assets/stylesheets/colors.scss';

.row3 {
  > div {
    margin-right: 23px;
    width: 31%;
  }
}

.divider {
  border-color: $surface_50;
}

.titleRow {
  flex-direction: row;
  display: flex;
  margin-bottom: 24px;
}

.title {
  margin-right: 5px;
  font-weight: 600;
}

.addButton {
  margin-bottom: 24px;
}

.banner {
  position: absolute;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 65px;
  z-index: 1;
}

.row {
  justify-content: space-between;
  gap: 23px;
}

.rowInput {
  flex: 1;
}
