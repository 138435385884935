/*
  All generic variables go here. Please avoid
  adding code that generates CSS in this file.
*/

// INFO: Using Ant's suggestions on selecting Font Family: https://ant.design/docs/spec/font#font-family 
// The idea is that it uses system default font family to make sure it's always user friendly, stable and professional to the end user.
$primary-font-family: -apple-system, BlinkMacSystemFont,
“Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
“Fira Sans”, “Droid Sans”, “Helvetica Neue”,
sans-serif;

$spinner-z-index: 2;

/* Breakpoint-related variables */
$breakpoints: (
  xl: 1400px,
  lg: 1200px,
  md: 992px,
  sm: 768px,
  xs: 576px,
);

$items-per-row: (
  xl: 5,
  lg: 4,
  md: 3,
  sm: 2,
  xs: 2,
  xxs: 1,
);
