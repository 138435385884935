.title {
  font-size: 12px;
  font-weight: 600;
}

.content {
  font-size: 14px;
  margin-bottom: 10px;
}

.row {
  display: flex;
  gap: 100px;
}
