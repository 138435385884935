@import 'src/assets/stylesheets/colors.scss';

.paginatedContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.divider {
  border-color: $surface_50;
}

.pageTitle {
  font-weight: 500;
  margin-bottom: 5px;
}

.pageItem {
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.input {
  height: 400px;
  width: 330px;
  margin-bottom: 70px;
  border-color: $surface_30;
  :global {
    .ql-toolbar {
      height: 70px;
    }
    .ql-tooltip {
      z-index: 1;
      transform: translate(120px, 0px);
    }
    .ql-container {
      font-size: 14px;
    }
  }
}

.multilineInput {
  height: 400px;
  width: 330px;
  border-color: $surface_30;
}

.multilineInput:hover, .multilineInput:focus {
  border-color: $surface_30;
}

.audioButton {
  margin-top: 20px;
}

.audioRow {
  align-items: center;
  display: flex;
}

.deleteButton {
  margin-left: 20px;
}
