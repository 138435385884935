@import 'src/assets/stylesheets/colors.scss';

.navigatorTaskCard {
  display: flex;
  width: 65%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid #0000000F;
  background: $surface_10;

  .form {
    width: 100%;
  }

  .topSection {
    display: flex;
    padding: 8px 12px;
    gap: 8px;
    width: 100%;
    justify-content: space-between;

    .topSectionInputs {
      align-items: center;
      display: flex;
      gap: 10px;
      width: 90%;

      .orderInput {
        width: 70px;
      }

      .titleInput {
        width: 100%;
      }
    }
  }
}

.bottomSection {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  border-top: 1px solid #0000000F;

  .dateInputs {
    display: flex;
    gap: 16px;
    flex-direction: row;
    .selectType {
      width: 200px;
    }
  }

  .bottomButtons {
    padding: 8px 0px;
    display: flex;
    gap: 10px;
  }
}

.deleteButtonLabel {
  text-wrap: nowrap;
}
