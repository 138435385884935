@import 'src/assets/stylesheets/colors.scss';

.spaceBwTables {
  margin-top: 15px;
}

.tableTitle {
  font-size: 16px;
  font-weight: bolder;
  align-items: center;
  display: flex;
  margin-bottom: -14px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: bold;
  color: $surface_70;
  line-height: 16px;
  margin-bottom: 8px;
  position: relative;
}

.sectionSubtitle {
  font-size: 16px;
  color: $surface_70;
  line-height: 16px;
  margin-bottom: 8px;
}

.socialNeedsContainer {
  columns: 2 auto;
}

.socialNeedInput {
  margin-bottom: 16px;
}

.socialNeedCheckbox {
  margin-bottom: 0px;
}

.submitButtonContainer {
  display: flex;
}

.buttonSeparator {
  width: 5px;
}
