@import 'src/assets/stylesheets/colors.scss';

.quillHeader {
    height: 70px;
    border-width: 2px;
    border: 1px solid;
    border-bottom-width: 0px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}