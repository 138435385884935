@import 'src/assets/stylesheets/colors.scss';

.title {
  font-size: 12px;
  font-weight: 600;
}

.content {
  font-size: 14px;
  margin-bottom: 10px;
}

.image {
  object-fit: cover;
  border-radius: 50%;
  border-width: 1px;
  border-color: $surface_50;
}

.topSection {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.contentSection {
  margin-left: 24px;
}

.survivorsList {
  padding-left: 15px;
}

.listItem {
  margin-bottom: 8px;
}
