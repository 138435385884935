@import 'src/assets/stylesheets/colors';

.navBarTitle {
  color: $surface_10;
  font-size: 26px;
  margin-right: 20px;
}

.navBarContainer {
  align-items: center;
  display: flex;
}

.menu {
  flex: 1;
}

.avatar {
  border-color: $surface_10;
  border-width: 2px;
}
