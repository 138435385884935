@import 'src/assets/stylesheets/colors.scss';

.spaceBwTables {
  margin-bottom: 24px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.articlesDrawer {
  :global {
    .ant-drawer-content-wrapper {
      box-shadow: none;
    }
  }
}