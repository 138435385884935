@import 'src/assets/stylesheets/colors.scss';
@import 'src/assets/stylesheets/variables';

.cardContainer {
  margin-bottom: 16px;
  width: 50%;
}

.separator {
  background-color: $surface_50;
  height: 1px;
  margin-top: 10px;
}

.reaction {
  align-items: center;
  display: flex;
}

.reactionButton {
  margin: 0px;
  margin-left: -5px;
}

.authorContainer {
  margin-left: 15px;
}

// TODO: Find the way to use the font styles from theme
.authorName {
  font-family: $primary-font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
}

.authorType {
  font-family: $primary-font-family;
  font-size: 16px;
  color: $surface_70;
  line-height: 16px;
}

.authorHour {
  font-family: $primary-font-family;
  font-size: 16px;
  color: $surface_70;
  line-height: 16px;
}

.postHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.authorMetaInfo {
  display: flex;
  align-items: center;
}

.authorInfoContainer {
  margin-left: 15px;
  display: grid;
  gap: 5px;
}

.postContent {
  font-family: $primary-font-family;
  font-size: 16px;
  font-style: normal;
  margin-top: 15px;
}

.postImage {
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
}

.commentAuthorName {
  font-family: $primary-font-family;
  font-size: 16px;
  font-style: normal;
  margin-left: 15px;
}

.commentOutsideContainer {
  margin-top: 20px;
  display: flex;
}

.commentContainer {
  flex-grow: 1;
}

.commentContent {
  margin-left: 15px;
  display: grid;
}

.commentImage {
  border-radius: 10px;
  width: 75%;
  margin-top: 20px;
}

.commentHour {
  font-family: $primary-font-family;
  font-size: 14px;
  color: $surface_70;
  margin-left: 10px;
}

.commentReaction {
  align-items: center;
  display: flex;
  margin-top: 5px;
}

.likeIcon {
  color: $red_7;
}

.commentInputForm {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
