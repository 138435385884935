@import 'src/assets/stylesheets/colors.scss';

.green {
  background-color: $green_1;
  border-color: $green_7;
  color: $green_9;
}

.gold {
  background-color: $gold_1;
  border-color: $gold_7;
  color: $gold_9;
}

.gray {
  background-color: $surface_30 !important;
  border-color: $surface_50 !important;
  color: $surface_70 !important;
}

.lime {
  background-color: $lime_1;
  border-color: $lime_7;
  color: $green_9;
}

.geekblue {
  background-color: $geekblue_1;
  border-color: $geekblue_5;
  color: $geekblue_7;
}

.cyan {
  background-color: $cyan_1;
  border-color: $cyan_7;
  color: $cyan_9;
}

.volcano {
  background-color: $volcano_1;
  border-color: $volcano_8;
  color: $volcano_8;
}

.magenta {
  background-color: $magenta_1;
  border-color: $magenta_7;
  color: $magenta_8;
}