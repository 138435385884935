/*
  Add colors as variables here like this:

  $color-name: #000000;

  Please refrain from adding code that generates CSS.
*/

// TODO: Color palette used in mobile app. Added as an example

$button_gradient_info_1: #3044ab;
$button_gradient_info_2: #263689;
$button_gradient_primary_1: #d1491f;
$button_gradient_primary_2: #bd3911;
$complimentary_10: #f7f6f5;
$complimentary_30: #fcefce;
$complimentary_50: #eeae0b;
$complimentary_70: #de8431;
$complimentary_90: #8b1900;
$error_10: #f6d3d3;
$error_30: #e47c7c;
$error_50: #d22525;
$error_70: #a81e1e;
$error_90: #691212;
$info_10: #d6daee;
$info_30: #838fcd;
$info_50: #3044ab;
$info_70: #263689;
$info_90: #182256;
$ios_button: #307bf6;
$overlay_accomplish: #af2e07d9;
$overlay_connect: #de8431cc;
$overlay_learn: #2d0554cc;
$overlay_profile: #3044abcc;
$primary_50: #2c4bcd;
$secondary_10: #d5cddd;
$secondary_30: #816998;
$secondary_50: #2d0554;
$secondary_70: #240443;
$secondary_90: #16022a;
$success_10: #dfede8;
$success_30: #76b888;
$success_50: #20a144;
$success_70: #166d2e;
$success_90: #0e441c;
$surface_10: #ffffff;
$surface_30: #e6e6e6;
$surface_50: #bfbfbf;
$surface_70: #474747;
$surface_90: #1e1b20;
$transparent: transparent;
$red_7: #cf1322;
$red_5: #ff4d4f;
$green_1: #f6ffed;
$green_7: #389e0d;
$green_9: #135200;
$lime_1: #fcffe6;
$lime_7: #5b8c00;
$geekblue_1: #f0f5ff;
$geekblue_5: #597ef7;
$geekblue_7: #1d39c4;
$cyan_1: #e6fffb;
$cyan_7: #08979c;
$cyan_9: #00474f;
$volcano_1: #fff2e8;
$volcano_8: #ad2102;
$magenta_1: #fff0f6;
$magenta_7: #c41d7f;
$magenta_8: #9e1068;
$gold_1: #fffbe6;
$gold_7: #d48806;
$gold_8: #ad6800;
$gold_9: #874d00;
$bg_layout: #f5f5f5;
$ant_info_table_background: #00000005;
$ant_info_table_border: #0505050f;
$orange_8: #ad4e00;
$border_gray: #e0e0e0;
$color_border: #00000026;
$color_border_secondary: #0000000F;

// INFO: Defined by the designer, based on Ant design colors
// TODO: Check how we can refine the design system's color, she said she based on Ant for all colors.
$color-text: #000000e0;
$color-text-label: #000000a6;

:export {
  primary_50: $primary_50;
  button_gradient_info_1: $button_gradient_info_1;
  button_gradient_info_2: $button_gradient_info_2;
  button_gradient_primary_1: $button_gradient_primary_1;
  button_gradient_primary_2: $button_gradient_primary_2;
  complimentary_10: $complimentary_10;
  complimentary_30: $complimentary_30;
  complimentary_50: $complimentary_50;
  complimentary_70: $complimentary_70;
  complimentary_90: $complimentary_90;
  error_10: $error_10;
  error_30: $error_30;
  error_50: $error_50;
  error_70: $error_70;
  error_90: $error_90;
  info_10: $info_10;
  info_30: $info_30;
  info_50: $info_50;
  info_70: $info_70;
  info_90: $info_90;
  ios_button: $ios_button;
  overlay_accomplish: $overlay_accomplish;
  overlay_connect: $overlay_connect;
  overlay_learn: $overlay_learn;
  overlay_profile: $overlay_profile;
  secondary_10: $secondary_10;
  secondary_30: $secondary_30;
  secondary_50: $secondary_50;
  secondary_70: $secondary_70;
  secondary_90: $secondary_90;
  success_10: $success_10;
  success_30: $success_30;
  success_50: $success_50;
  success_70: $success_70;
  success_90: $success_90;
  surface_10: $surface_10;
  surface_30: $surface_30;
  surface_50: $surface_50;
  surface_70: $surface_70;
  surface_90: $surface_90;
  transparent: $transparent;
  red_5: $red_5;
  red_7: $red_7;
  lime_1: $lime_1;
  lime_7: $lime_7;
  geekblue_1: $geekblue_1;
  geekblue_5: $geekblue_5;
  geekblue_7: $geekblue_7;
  cyan_1: $cyan_1;
  cyan_7: $cyan_7;
  cyan_9: $cyan_9;
  volcano_1: $volcano_1;
  volcano_8: $volcano_8;
  magenta_1: $magenta_1;
  magenta_7: $magenta_7;
  magenta_8: $magenta_8;
  green_1: $green_1;
  green_7: $green_7;
  green_9: $green_9;
  gold_1: $gold_1;
  gold_7: $gold_7;
  gold_8: $gold_8;
  gold_9: $gold_9;
  bg_layout: $bg_layout;
  color-text: $color-text;
  color-text-label: $color-text-label;
  ant_info_table_background: $ant_info_table_background;
  ant_info_table_border: $ant_info_table_border;
  orange_8: $orange_8;
  border_gray: $border_gray;
  color_border: $color_border;
  color_border_secondary: $color_border_secondary;
}
