@import 'src/assets/stylesheets/colors.scss';

.tabInfoContainer {
  box-shadow: 0px 6px 24px -15px $surface_50;
}

.container {
  width: '100%';
  background-color: $surface_10;
}

.tabs {
  :global {
    .ant-tabs-nav {
      margin: 0px;
    }
  }
}