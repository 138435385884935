@import 'src/assets/stylesheets/colors.scss';

.row {
  display: flex;
  flex-direction: row;
}

.phoneInput {
  margin-right: 30px;
  min-width: 250px;
}

.preferredPhone {
  margin-top: 20px;
}
