@import 'src/assets/stylesheets/colors.scss';

.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
}

.subHeader {
  margin-left: 0px;
  background-color: $bg_layout;
  border-bottom-width: 1px;
  border-bottom-color: $border_gray;
  border-bottom-style: solid;
}

.sideBar {
  min-height: 100vh;
}

.menu {
  padding: 4px;
}

.titleContainer {
  padding: 0 24px;
  flex-direction: row;
  display: flex;
  background-color: $surface_10;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  margin: 16px 0px;
  font-weight: 600;
}

.table {
  :global {
    .ant-table-container {
      border-start-start-radius: 0px;
      border-start-end-radius: 0px;
    }
  }
}
