@import 'src/assets/stylesheets/colors.scss';

.button {
  margin-right: 15px;
  margin-left: 15px;
}

.buttonContainer {
  align-items: flex-end;
}

.separator {
  background-color: $surface_30;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100vw;
  right: 0px;
  position: absolute;
}

.separatorContainer {
  height: 40px;
}

.table {
  margin-top: -16px;
}

.infoTable {
  margin-bottom: 20px;
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabLabel {
  color: $surface_90;
}

.groupPosts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textArea {
  flex: 1;
}

.uploadButton {
  margin-top: 8px;
}
.newPostImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.noPosts {
  background-color: $surface_10;
  padding: 20px;
  transform: translateY(-16px);
  margin-bottom: 50px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid $ant_info_table_border;
  border-top: none;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  background-color: $surface-70;
}

.imageButtons {
  display: flex;
  gap: 8px;
}
