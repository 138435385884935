
@import 'src/assets/stylesheets/colors.scss';

.navigatorTaskCard {
  display: flex;
  width: 65%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid $color_border_secondary;
  background: $surface_10;

  .topSection {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    height: 48px;
    
    .positionAndTitle {
      display: flex;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      flex-direction: row;
    }
  }

  .bottomSection {
    display: flex;
    padding: 12px;
    align-items: center;
    align-self: stretch;
    gap: 16px;
    border-top: 1px solid $color_border_secondary;

    .statusLabel {
      display: flex;
      align-items: center;
    }
  }
}

.skeletonHeight {
  height: 22px !important;
}
