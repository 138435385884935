.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
  padding-bottom: 60px;
}

.subHeader {
  margin-left: 0px;
  margin-bottom: 15px;
}

.buttonStyle {
  margin: 20px 40px 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.table {
  margin-top: -15px;
}
