@import 'src/assets/stylesheets/colors.scss';

.container {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  background-color: $info_30;
}

.loginContainer {
  background-color: $info_10;
  padding: 2em;
  border-radius: 0.25em;
  width: 300px;
  height: 390px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
