@import 'src/assets/stylesheets/colors.scss';

.separator {
  background-color: $surface_30;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100vw;
  right: 0px;
  position: absolute;
}

.separatorContainer {
  height: 40px;
}

.container {
  background-color: $surface_10;
  padding: 30px;
  box-shadow: 0px 6px 24px -15px $surface_50;
  margin-top: -16px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-bottom: 60px;
}

.submitButtonContainer {
  display: flex;
}

.buttonSeparator {
  width: 5px;
}
