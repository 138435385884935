@import 'src/assets/stylesheets/colors.scss';

.container {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  background-color: $info_30;
}

.loginContainer {
  background-color: $info_10;
  padding: 20px;
  border-radius: 0.25em;
  width: 300px;
  height: 390px;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin: 30px 0 30px;
}
