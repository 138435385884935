.table {
  margin-top: 15px;
}

.buttonContainer {
  padding-bottom: 20px;
  padding-top: 20px;
}

.addButton {
  margin-right: 10px;
}