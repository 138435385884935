@import 'src/assets/stylesheets/colors.scss';

.container {
  margin-top: 25px;
}

.table {
  margin-top: 24px;
}

.tableTitle {
  margin: 10px 0 0 10px;
  font-weight: bold;
}

.textSeparator {
  margin-bottom: 10px;
}

.navigatorTopSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}

.dateInput {
  width: 200px;
}

.content {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  gap: 24px;
}

.formItem {
  margin: 0px;
}

.divider {
  margin: 0px;
  background-color: $border_gray;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.adminTopSection {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.completedBySelect {
  width: 40%;
}

.selectSpokeToAdmin {
  width: 72%;
}

.selectSpokeToNav {
  flex: 1;
}
