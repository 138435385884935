@import 'src/assets/stylesheets/colors.scss';

.title {
  font-size: 14px;
  font-weight: 500;
}

.description {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.88;
  word-wrap: break-word;
  white-space: pre-line;
}
