@import 'src/assets/stylesheets/colors.scss';

.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
}

.title {
  font-weight: bold;
}

.subHeader {
  height: 80px;
}

.personalInfoContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.rightTable {
  margin-left: 20px;
  flex: 1;
}

.image {
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 2px 4px 10px $surface_50;
}