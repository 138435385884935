@import 'src/assets/stylesheets/colors.scss';

.breadcrumbs {
  padding-top: 24px;
}

.breadcrumbTitle {
  font-size: 24px;
  display: block;
  line-height: 1;
}

.container {
  background-color: $surface_10;
  width: 100vw;
  margin-left: -50px;
  padding-right: 50px;
  height: 126px;
  padding-left: 50px;
  padding-bottom: 20px;
  box-shadow: 0px 6px 24px -15px $surface_50;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.sectionTitle {
  font-size: 12px;
  font-weight: 600;
  display: block;
  line-height: 2;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
}
