@import 'src/assets/stylesheets/colors.scss';

.container {
    background-color: $surface_10;
    padding: 30px;
    box-shadow: 0px 6px 24px -15px $surface_50;
    margin-top: -16px;
    border-radius: 5px;
    margin-bottom: 40px;
}

.sendButton {
    margin: 0px 10px 0px 10px;
}

.form {
    display: flex;
}

.flexible {
    flex: 1;
}

.formItem {
    margin-bottom: 0px;
    flex: 1;
    background-color: $bg_layout;
    border-radius: 20px;
}

.textArea {
    flex: 1;
}


.inputContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    border-radius: 10px;
    background-color: $bg_layout;
}

.sendButtonContainer {
    display: flex;
}

.imagePreview {
    border-radius: 3px;
    margin: 20px;
}

.deleteImageButton {
    position: absolute;
    left: 260px;
    top: 60px;
}

.uploadButton {
    background-color: $bg_layout;
}

.actionButtonContainer {
    display: flex;
    justify-content: space-between;
}

.contentEditable {
    display: flex;
    flex-direction: column;
}

.submitButton {
    align-self: flex-end;
}
