.buttonContainer {
  padding-bottom: 20px;
  padding-top: 20px;
}

.addButton {
  margin-right: 10px;
}

.summary,
.summary div {
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.table {
  margin-top: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  > div:first-child {
    margin-right: 20px;
    width: 70%;
  }
  > div:last-child {
    width: 20%;
  }
}

.item {
  margin-bottom: 20px;
}

.fitContent {
  width: 100%;
}

.notesInput {
  height: 348px;
}

.container {
  padding-bottom: 50px;
}
