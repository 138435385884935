@import 'src/assets/stylesheets/colors.scss';

.filterButton {
  width: 90px;
}

.searchInput {
  display: block;
  margin-bottom: 8px;
}

.searchContainer {
  padding: 8px;
}

.highlightStyle {
  background-color: $complimentary_50;
}

.iconFiltered {
  color: $ios_button;
}
