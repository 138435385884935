@import 'src/assets/stylesheets/colors.scss';

.paginatedContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.divider {
  border-color: $surface_50;
}

.pageTitle {
  font-weight: 500;
  margin-bottom: 5px;
}

.pageItem {
  margin-bottom: 10px;
}

.ssmlButton {
  margin-top: 20px;
}
.input {
  :global {
    .ql-tooltip {
      transform: translate(120px, 0px);
    }
  }
}