@import 'src/assets/stylesheets/colors.scss';

.submitButtonContainer {
  display: flex;
}

.buttonSeparator {
  width: 5px;
}

.tabLabel {
  color: $surface_90;
}

.tabInfoContainer {
  background-color: $surface_10;
  margin-top: 16px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.row {
  display: flex;
  flex-direction: row;
}

.phone {
  margin-right: 10px;
}
