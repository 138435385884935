@import 'src/assets/stylesheets/colors.scss';

.documentsTabContainer {
  background-color: $surface_10;
  margin-top: 16px;
}

.fileCard {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.inputContainer {
  flex: 1;
  border-radius: 10px;
  margin-top: 20px;
}

.form {
  width: 100%;
}
