@import 'src/assets/stylesheets/colors.scss';

.container {
  background-color: $surface_10;
  margin-top: 16px;
}

.empty {
  align-items: center;
  display: flex;
  justify-content: center;
}

.topButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.learnTrackContainer {
  display: flex;
  flex-direction: row;
}

.learnTrackLabel {
  align-self: center;
  margin-right: 10px;
}

.learnTrackSelect {
  width: 195px;
}

.assignPackageButton {
  margin-right: 10px;
}

.deploymentDateTitle {
  display: flex;
  justify-content: space-between;
}

.actionButtonContainer {
  display: flex;
  justify-content: space-between;
}

.buttonSeparator {
  margin-right: 10px;
}

.listButton {
  vertical-align: text-top;
  height: 100%;
  margin-top: -8px;
}

.listContainer {
  padding: 15px;
}

.deploymentDateRow {
  display: flex;
  flex-direction: row;
}

.leftButton {
  margin-left: 10px;
  padding: 0px 8px;
}
