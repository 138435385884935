@import 'src/assets/stylesheets/colors.scss';

.rightButton {
    margin-left: 10px;
}

.buttonDanger {
    color: $red_7;
    border-color: $red_7;
}
