@import 'src/assets/stylesheets/colors.scss';

.warningIcon {
  color: $gold_7;
  margin-right: 10px;
}

.infoIcon {
  color: $surface_50;
  margin-right: 10px;
}

.checkboxContainer {
  margin-bottom: 20px;
}

.selectAssesementContainer {
  height: 100px;
  position: relative;
}

.warningContainer {
  margin-top: 20px;
}

.infoContainer {
  position: absolute;
  bottom: 15px;
}
