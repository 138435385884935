@import 'src/assets/stylesheets/colors.scss';

.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
}

.title {
  font-weight: bold;
}

.subHeader {
  margin-left: 0px;
  background-color: $bg_layout;
  border-bottom-width: 1px;
  border-bottom-color: $border_gray;
  border-bottom-style: solid;
}

.table {
  :global {
    .ant-table-container {
      border-start-start-radius: 0px;
      border-start-end-radius: 0px;
    }
  }
}

.suicidalityEmailsLink {
  padding-left: 0px;
  padding-right: 0px;
}

.subHeaderButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
