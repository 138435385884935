@import 'src/assets/stylesheets/colors.scss';

.buttonSeparator {
  width: 5px;
}

.separator {
  background-color: $surface_30;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100vw;
  right: 0px;
  position: absolute;
}

.separatorContainer {
  height: 40px;
}

.submitButtonContainer {
  display: flex;
}
