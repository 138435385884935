@import 'src/assets/stylesheets/colors.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.slider {
  margin: 0px 40px 0px 20px
}

.step {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.validationText {
  display: flex;
  flex-direction: column;
}

.disabledQuestion {
  opacity: 0.3;
}

.radioButtonError {
  :global {
    .ant-radio-inner {
      border-color: $red_7;
    }
  }
}

.titleError {
  color: $red_7;
}

.formItem {
  :global {
    .ant-form-item-explain-error {
      margin-top: 16px;
    }
  }
}
