@import 'src/assets/stylesheets/colors.scss';

.submitButtonContainer {
  display: flex;
}

.buttonSeparator {
  width: 5px;
}

.separatorBWEntities {
  background-color: $surface_30;
  height: 1px;
  margin-bottom: 15px;
}
