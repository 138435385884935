@import 'src/assets/stylesheets/colors.scss';

.container {
  background-color: $surface_10;
  margin-top: 16px;
}

.updateGoalButton {
  margin-left: 7px;
}

.timeSpent {
  display: flex;
  justify-content: flex-end;
}

.flexible {
  flex: 1;
}

.inputSeparation {
  margin-left: 23px;
}

.selectorsContainer {
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
}

.formRow {
  display: flex;
}

.taskDescriptionCell {
  width: 234px;
}

.pseudoLink {
  color: $primary_50;
  text-decoration: underline;
  cursor: pointer;
}

.optionalLabel {
  color: #000000a6;
}

.notesInput {
  height: 348px;
}
