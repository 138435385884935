@import 'src/assets/stylesheets/colors.scss';
.spaceBwTables {
  margin-bottom: 15px;
}

.title {
  font-weight: bold;
  margin-top: 15px;
}

.submitButtonContainer {
  display: flex;
}

.buttonSeparator {
  width: 5px;
}

.tableContainer {
  margin-bottom: 20px;
  margin-left: -10px;
}

.emptyText {
  color: $surface_50;
}
