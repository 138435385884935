@import 'src/assets/stylesheets/colors.scss';

.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
}

.subHeader {
  z-index: 1;
  margin-left: 0px;
  background: $surface_10;
  box-shadow: 0px 1px 20px 0px $color_border;
}

.sticky {
  position: fixed;
  top: 0px;
}

.listContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.listPadding {
  padding-top: 150px;
}

.buttonsContainer {
  gap: 10px;
  display: flex;
}

.fab {
  bottom: 80px;
  right: 20px;
  height: 48px;
  width: 48px;
}