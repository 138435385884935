@import 'src/assets/stylesheets/colors.scss';

.button {
  margin-right: 15px;
}

.buttonContainer {
  align-items: flex-end;
}

.submitButtonContainer {
  display: flex;
}

.separator {
  background-color: $surface_30;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100vw;
  right: 0px;
  position: absolute;
}

.tabLabel {
  color: $surface_90;
}

.separatorContainer {
  height: 40px;
}

.tabInfoContainer {
  background-color: $surface_10;
  padding: 30px;
  box-shadow: 0px 6px 24px -15px $surface_50;
  margin-top: -16px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-bottom: 60px;
}

.buttonSeparator {
  width: 5px;
}

.editButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.badge {
  margin-left: 5px;
  width: 11px;
  height: 11px;
  border-radius: 100%;
}

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabContainer {
  background-color: $surface_10;
  padding: 20px;
  box-shadow: 0px 6px 24px -15px $surface_50;
  margin-top: -16px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-bottom: 60px;
}

.title {
  margin-bottom: 10px;
  font-size: medium;
  font-weight: bolder;
}

.topContainer {
  margin-bottom: 20px;
}
