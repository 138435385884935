@import 'src/assets/stylesheets/colors.scss';

.row {
    display: flex;
    flex-direction: row;
}

.firstReminderInput {
    margin-right: 20px;
}

.repeatScheduleSelect { 
    width: 200px !important;
}

.title {
    max-width: 200px;
    overflow: hidden;
}

.note {
    color: $surface_70;
}

.titleInputContainer :global(.ql-tooltip) {
    left: 0px !important;
}
