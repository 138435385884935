@import 'variables';
@import 'colors';

/*
  This file contains defaults for elements of the DOM.
  If you need to change the default appearance of a DOM element add
  that code here.
*/
* {
  box-sizing: border-box;
  font-family: $primary-font-family;
}

body {
  margin: 0;
  background-color: $complimentary_10;
}

// We're probably never going to use the default background
// or border of the buttons, so we remove it here to avoid duplicating
// code all over.
button {
  background: 'none';
  border: none;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}

//styles for the quill editor
.ql-toolbar {
  border-radius: 6px 6px 0px 0px;
  border-color: $surface_30 !important;
}

.ql-container {
  border-radius: 0px 0px 6px 6px;
  border-color: $surface_30 !important;
}

pre,
.avoid-overflow {
  white-space: pre-line;
  word-break: break-word;
}

.ant-btn-link {
  text-align: left;
}

.optional-label {
  color: $color-text-label;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
