@import 'src/assets/stylesheets/colors.scss';

.uploadingLabelContainer {
  flex-direction: row;
  display: flex;
}

.input {
  :global {
  .ql-tooltip {
    transform: translate(120px, 0px);
    }
  }
}

.uploadingLabel {
  margin-left: 10px;
  color: $color-text-label;
}
