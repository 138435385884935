@import 'src/assets/stylesheets/colors.scss';

.dropdown {
  margin-top: 20px;
}

.selectedItemsContainer {
  margin-left: 8px;
}

.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
}

.subHeader {
  margin-left: 0px;
}

.buttonStyle {
  margin: 20px 40px 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.table {
  margin-top: 0px;
}
