@import 'src/assets/stylesheets/colors.scss';

.upload {
  :global {
    .ant-upload {
      width: 335px !important;
      justify-content: center !important;
      align-items: center !important;
      height: 115px !important;
    }
  }
}

.uploadLabel {
  margin-top: 8px;
}

.imagePreview {
  object-fit: cover;
  border-radius: 10px;
}

.imagePreviewContainer {
  width: 335px;
  height: 115px;
}

.overlay {
  opacity: 0;
  position: absolute;
  width: 335px;
  height: 115px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in-out;
}

.overlay:hover {
  transition: 0.1s ease-in-out;
  opacity: 0.6;
  background-color: $surface-90;
}

.icon {
  color: $surface-10;
  font-size: 25px;
}

.separator {
  width: 15px;
}

.optionalLabel {
  color: $surface-70;
  opacity: 0.8;
  margin-bottom: 2px;
  margin-top: 0px;
}

.imageLabel {
  margin-top: 0px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.rowInput {
  flex: 1;
}

.assignToUserSelect {
  width: 470px !important;
}

.row {
  justify-content: space-between;
  gap: 24px;
}

.deleteIcon {
  color: $surface-70;
}

.menuIcon {
  @extend .deleteIcon;
  cursor: move;
  touch-action: none;
}

.deleteButton {
  margin-bottom: 10px;
}

.table {
  margin-bottom: 30px;
}

.loading {
  margin-right: 10px;
}
