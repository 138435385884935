@import 'src/assets/stylesheets/colors';

.container {
  background-color: $surface_10;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
}

.option {
  border-color: $transparent !important;
  cursor: pointer;
  font-size: 12px !important;
  padding: 8px !important;
}

.mainContainer {
  display: inline-block;
  position: relative;
}

.tag {
  display: flex;
  justify-content: space-between;
  width: 120px;
}
