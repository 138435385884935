@import 'src/assets/stylesheets/colors.scss';

.uploadingLabelContainer {
  display: flex;
  flex-direction: row;
}

.uploadingLabel {
  margin-left: 10px;
  color: $color-text-label;
  display: inline-block;
  clip-path: inset(0 1ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}
