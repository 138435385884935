@import 'src/assets/stylesheets/colors.scss';

.container {
  background-color: $surface_10;
  margin-top: 16px;
}

.notifyUncompletedButton {
  margin-bottom: 30px;
}

.table {
  margin-top: 30px;
}
