@import 'src/assets/stylesheets/colors.scss';

.sendButton {
  margin: 0px 10px 0px 10px;
}

.flexible {
  flex: 1;
}

.formItemTextArea {
  margin-bottom: 0px;
  flex: 1;
  background-color: $bg_layout;
  border-radius: 20px;
}

.textArea {
  flex: 1;
}

.inputContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 10px;
  background-color: $bg_layout;
  position: relative;
}

.sendButtonContainer {
  display: flex;
}

.imagePreview {
  border-radius: 3px;
  margin: 20px;
}

.deleteImageButton {
  position: absolute;
  left: 230px;
  top: 30px;
}

.uploadButton {
  background-color: $bg_layout;
}

.formItemUploadImage {
  margin-bottom: 0px;
  background-color: $bg_layout;
  border-radius: 20px;
}

.previewImageContainer {
  position: relative;
}
