@import 'src/assets/stylesheets/colors.scss';

.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
}

.tabInfoContainer {
  background-color: $surface_10;
  box-shadow: 0px 6px 24px -15px $surface_50;
  margin-top: -16px;
}

.subHeader {
  margin-left: 0px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;
  padding-right: 15px;
}

.buttonStyle {
  margin-left: 10px;
}
