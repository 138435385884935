@import 'src/assets/stylesheets/colors';

.table {
  margin-top: 15px;
}

.deploymentDateTitle {
  display: flex;
  justify-content: space-between;
}

.icon {
  color: $surface_70;
}

.firstButton {
  margin-bottom: 15px;
}

.formItem {
  margin: 0;
}

.leftButton {
  margin-right: 10px;
}

.modalParagraph {
  font-size: 12px;
  font-style: italic;
  line-height: 20px;
}

.select {
  margin-bottom: 20px;
  display: flex;
}
