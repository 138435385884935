.container {
  align-items: 'center';
  flex-direction: 'column';
  justify-content: 'center';
  padding-bottom: 60px;
}

.subHeader {
  margin-left: 0px;
  margin-bottom: 15px;
}

.table {
  margin-top: -15px;
}
