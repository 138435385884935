@import 'src/assets/stylesheets/colors.scss';

.formItemUploadImage {
  margin-top: -10px;
}

.imageUpload {
  :global {
    .ant-upload-select {
      height: 160px !important;
      width: 160px !important;
    }
  }
}

.uploadButton {
  background: none;
  border: 0px;
  height: 160px;
  width: 160px;
}

.uploadButtonLabel {
  margin-top: 6px;
}

.topSection {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.fullNameSection {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.deleteButton {
  left: 160px;
  position: absolute;
  top: 145px;
}

.previewImage {
  border-radius: 160px;
}

.inputContainer {
  width: 100%;
}

.nameSection {
  width: 50%;
}

.phoneSection {
  width: 48%;
}
